<template>
  <div class="left-section">
    <Navigation />
  </div>
</template>

<script>
import Navigation from './Navigation.vue';

export default {
  components: {
    Navigation,
  },
};
</script>
