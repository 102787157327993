<template>
  <iframe
    class="league"
    src="https://league.superhero.com/?iframe=1"
  />
</template>

<script>
export default {
  metaInfo: {
    title: 'Superhero League',
  },
};
</script>

<style lang="scss" scoped>
.league {
  border: none;
}
</style>
